/* eslint-disable react/prop-types */
import React from "react";
import {useTranslation} from "react-i18next";
// import classnames from "classnames";
// import propTypes from "prop-types";
import FlexRow from "@/components/FlexRow";
import FlexCol from "@/components/FlexCol";

import useWindowSize from "@/hooks/useWindowSize";

import css from "./style.less";
import footerimage from "./assets/footerimg.jpg";

import SubjectBlock from "./elements/SubjectBlock";

export default function DesktopFooter({footer_content}){
  const {t}=useTranslation();

  const {height}=useWindowSize();

  return (
    <FlexCol justify="center" align="center" className={css.bottom_block} style={{minHeight:height*0.5,backgroundImage:`url(${footerimage})`}}>
      <FlexRow className={css.learn_more}>
        {t("页脚部分.标题")}
      </FlexRow>
      <FlexRow align="top" className={css.subject_block}>
        {footer_content.map(({title,children})=>{
          return (
            <SubjectBlock key={title} title={title} subject_tree={children} />
        )})}
      </FlexRow>
      <div className={css.copy_right_block_container}>
        <div className={css.copy_right_block}>
          <span>{t("页脚部分.详情")}</span>
          <a style={{color:"white"}} href="#">{t("页脚部分.隐私政策")}</a>  |
          <a style={{color:"white"}} href="#">{t("页脚部分.使用条款")}</a>  |
          <a href="https://beian.miit.gov.cn/" target="_blank">ICP证:浙ICP备2022020518号</a>
        </div>
      </div>
    </FlexCol>
)}


DesktopFooter.propTypes={


};
DesktopFooter.defaultProps={


};
